<template>
  <v-app>
    <div class="confirm-account">
      <v-container>

        <v-row>
          <v-col>
            <div class="circle-logo text-center pt-12">
              <logo-img></logo-img>
            </div>
            <div class="logo-img">
              <div class="text-center">
                <logo class="logo" />
                <div class="caption primary--text">
                  Manager
                </div>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row
          align-content="center"
        >
          <v-col
            cols="12"
            sm="6"
            offset-sm="3"
          >
            <div class="text-center">
              <h1 class="h1">
                Confirm E-mail
              </h1>
              <p v-if="loading">
                <v-progress-circular
                  indeterminate
                ></v-progress-circular>
              </p>
              <p v-if="status.email">
                We sent a confirmation code to your email <b>{{ status.email }}</b>.
              </p>
            </div>

            <v-form>
              <v-text-field
                type="number"
                label="Confirmation Code"
                maxlength="6"
                v-model="code"
                :error-messages="error"
                @keydown="monitorInput"
                outlined
              >
              </v-text-field>

              <v-btn
                block
                v-if="showResend"
                color="primary"
                :loading="resending"
                :disabled="confirmSecs > 0"
                @click="resend"
              >
                Resend Code
                <span v-if="confirmSecs">
                  ({{ confirmSecs }})
                </span>
              </v-btn>

              <v-btn
                block
                v-if="!showResend"
                color="primary"
                type="submit"
                :disabled="confirming"
                :loading="confirming"
                @click="confirm"
              >
                Confirm
              </v-btn>

            </v-form>

            <p class="overline mt-6">
              Already have an account?
              <router-link :to="{name: 'Login'}">Sign In</router-link>
              <br>
              Forgot Password?
              <a href="/">Reset Password</a>
            </p>

          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
  import Logo from '@/components/Logo'
  import LogoImg from '@/components/LogoImg'
  import { getPendingAccount, resendConfirmation, confirmAccount } from '@/services/http/registrations'

  export default {
    name: 'ConfirmAccount',
    components: {
      Logo, LogoImg
    },
    data() {
      return {
        tickInterval: null,
        error: null,
        code: '',
        loading: true,
        confirming: false,
        resending: false,
        status: {}
      }
    },
    async created() {
      try {
        this.status = await getPendingAccount(this.$route.params.id)
        this.startTick()
      } catch(e) {
        this.error = e.toString()
      } finally {
        this.loading = false
      }
    },
    destroyed() {
      if (this.tickInterval)
        clearInterval(this.tickInterval)
    },
    methods: {
      async resend() {
        try {
          this.resending = true
          this.status = await resendConfirmation(this.$route.params.id)
        } catch(e) {
          this.error = e.toString()
        } finally {
          this.resending = false
        }
      },
      async confirm() {
        try {
          this.confirming = true
          await confirmAccount(this.status.id, this.code)
          await this.$toast('Account confirmed successfully. You may login now.', 'Success')
          this.$router.push({name: 'Login'})
        } catch(e) {
          this.error = e.toString()
        } finally {
          this.confirming = false
        }
      },
      monitorInput() {
        var v = this.code
        if (v && v.length > 6) {
          this.code = v.substr(0, 6)
          this.error = null
        }
      },
      startTick() {
        this.tickInterval = setInterval(() => {
          const secs = this.status.confirmation_code_resend_seconds
          if (secs && secs > 0) {
            this.status.confirmation_code_resend_seconds --
          }
        }, 1000)
      }
    },
    computed: {
      showResend() {
        if (this.code && this.code.length >= 6)
          return false
        return true
      },
      confirmSecs() {
        var s = this.status.confirmation_code_resend_seconds
        return s && s > 0 ? s : 0
      }
    }
  }
</script>
